<template>
  <div>
    <div>
      <v-card v-for="brew in breweries" :key="brew.id" color="black">
        <div v-if="brew.objectId === $route.params.id">
          <img :src="brew.logoURL" width="80%" height="180%" />
          <div v-if="brew.checked === true">
            <img src="@/assets/images/check_mark.png" width="50px" />
          </div>
          <v-card color="#ffffcc"><h2>{{ brew.name }}</h2>
            <p style="color:black;font-size:0.8rem;">{{ brew.description }}</p>
            <a style="text-decoration:none;" :href="brew.siteURL" target="_blank"><v-btn x-small class="py-2 mb-2" color="orange lighten-2">View Site</v-btn></a>
          </v-card>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Brew',
  data() {
    return {};
  },
  created() {
  },
  watch: {
    $route(to, from) {
      console.log(to);
      console.log(from);
      // react to route changes...
      this.loadData();
    },
  },
    computed: {
      ...mapGetters('brew', ['breweries'])
    },
};
</script>

<style>
</style>